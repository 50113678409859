<template>
      <!-- iq-card -->
      <b-col xs="12" sm="12" md="12" lg="12" xl="12">
        <iq-card>
          <template v-slot:body>
          <b-row class="details">
            <!-- beneficiary -->
            <b-table-simple striped bordered small hover>
            <tbody>
              <b-tr>
                <b-th colspan="1">{{ $t('globalTrans.organization') }}</b-th>
                <b-th colspan="5">{{ getOrgName(mainData.org_id) }}</b-th>
              </b-tr>

              <b-tr>
                <b-th colspan="1">{{ $t('soil_management.crop_type') }}</b-th>
                <b-th colspan="5">{{ getCropType(mainData.crop_type_id) }}</b-th>
              </b-tr>

              <b-tr>
                <b-th colspan="1">{{ $t('soil_management.crop') }}</b-th>
                <b-th colspan="5">{{ getCropName(mainData.crop_id) }}</b-th>
              </b-tr>

            </tbody>
           </b-table-simple>
<!-- detail -->
           <b-table-simple striped bordered small hover>
            <tbody>
              <b-tr>
                <b-th colspan="1">{{ $t('soil_management.characteristics_type') }}</b-th>
                <b-th colspan="1">{{ $t('soil_management.characteristics') }}</b-th>
                <b-th colspan="1">{{ $t('soil_management.details') }}</b-th>
                <b-th colspan="1">{{ $t('soil_management.effect_on_plants') }}</b-th>
              </b-tr>
            </tbody>
            <tbody>
              <b-tr v-for="(item, index) in mainData.details" :key="index">
                <b-td>
                  {{ getCharacteristicsType(item.char_type_id) }}
                </b-td>
                <b-td>
                  {{ getCharacteristics(item.char_id) }}
                </b-td>
                <b-td>
                  {{ item.details }}
                </b-td>
                <b-td>
                  {{ item.plant_effect }}
                </b-td>
              </b-tr>
            </tbody>
           </b-table-simple>
         </b-row>
          <b-col class="text-right">
                  <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
         </b-col>
          </template>
        </iq-card>
      </b-col>
</template>

<script>
import { core } from '@/config/pluginInit'

export default {
  props: ['id'],
  components: {
  },
  created () {
    if (this.id) {
      const tmp = this.getSoilInfoData()
      this.mainData = tmp
      this.mainData.details = tmp.details
      this.mainData.edit_id = this.id
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
        loadingState: false,
        saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
        mainData: {
            org_id: 0,
            crop_type_id: '',
            crop_id: '',
            details: [],
            edit_id: ''
        },
        detailData: {
          char_type_id: 0,
          char_id: 0,
          details: '',
          plant_effect: ''
        }
    }
  },
  methods: {
    getSoilInfoData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return tmpData === undefined ? {} : JSON.parse(JSON.stringify(tmpData))
    },
    getOrgName (id) {
          const objectData = this.$store.state.commonObj.organizationProfileList.find(item => item.value === id)
              if (this.$i18n.locale === 'bn') {
                  return objectData.text_bn
              } else {
                  return objectData.text_en
              }
    },
    getCropType (id) {
        const cropType = this.$store.state.VirtualMuseum.commonObj.cropTypeList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
            return cropType.text_bn
        } else {
            return cropType.text_en
        }
    },
    getCropName (id) {
        const cropName = this.$store.state.VirtualMuseum.commonObj.cropList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
            return cropName.text_bn
        } else {
            return cropName.text_en
        }
    },
    getCharacteristicsType (id) {
      const characteristicsType = this.$store.state.VirtualMuseum.commonObj.characteristicsTypeList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
            return characteristicsType.text_bn
        } else {
            return characteristicsType.text_en
        }
    },
    getCharacteristics (id) {
      const characteristics = this.$store.state.VirtualMuseum.commonObj.characteristicsList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
            return characteristics.text_bn
        } else {
            return characteristics.text_en
        }
    }
  }
}
</script>
