<template>
  <b-container fluid>
    <b-row>
      <b-col xl="12" lg="12" sm="12">
            <iq-card>
                <template v-slot:body>
                    <b-overlay :show="formLoader" rounded="sm">
                        <b-row>
                            <b-col xl="12" lg="12" sm="12">
                                <ValidationObserver ref="form1"  v-slot="{ handleSubmit, reset }">
                                    <b-form @submit.prevent="handleSubmit(addDetailData)" @reset.prevent="reset">
                                        <b-row>
                                            <b-col>
                                                <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="org_id"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                    {{$t('globalTrans.organization')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="mainData.org_id"
                                                    :options="orgList"
                                                    id="org_id"
                                                    :disabled="mainData.org_id != ''"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col>
                                                <ValidationProvider name="Crop Type" vid="crop_type_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="crop_type_id"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                    {{$t('soil_management.crop_type')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="mainData.crop_type_id"
                                                    :options="cropTypeList"
                                                    id="crop_type_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col lg="6" sm="6">
                                                <ValidationProvider name="Crop Name" vid="crop_name_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="crop_name_id"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                    {{$t('soil_management.crop')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="mainData.crop_id"
                                                    :options="cropList"
                                                    id="crop_name_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                        <hr>
                                        <b-row>
                                            <b-col>
                                                <ValidationProvider name="Characteristics Type" vid="charac_type_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="charac_type_id"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                    {{$t('soil_management.characteristics_type')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="detailData.char_type_id"
                                                    :options="characteristicsTypeList"
                                                    :change="getCharacteristicsList()"
                                                    id="charac_type_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col>
                                                <ValidationProvider name="Characteristics" vid="charac_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="charac_id"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                    {{$t('soil_management.characteristics')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="detailData.char_id"
                                                    :options="charactList"
                                                    id="charac_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col>
                                                <ValidationProvider name="Details" vid="detail_id">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="detail_id"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                    {{$t('soil_management.details')}}
                                                    </template>
                                                    <b-form-textarea
                                                    id="detail_id"
                                                    v-model="detailData.details"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-textarea>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col>
                                                <ValidationProvider name="Effect on Plant" vid="effect_id">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="effect_id"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                    {{$t('soil_management.effect_on_plants')}}
                                                    </template>
                                                    <b-form-textarea
                                                    id="effect_id"
                                                    v-model="detailData.plant_effect"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-textarea>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                        <div class="row">
                                            <div class="col-sm-3"></div>
                                                <div class="col text-right">
                                                <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.add') }}</b-button>
                                            </div>
                                        </div>
                                    </b-form>
                                </ValidationObserver>
                                <br/>
                                <!-- start add more  -->
                                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                    <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                                    <b-row>
                                                <table class="table table-bordered">
                                                    <thead class="text-white" style="background-color: #66cc99">
                                                        <tr>
                                                            <th>{{$t('globalTrans.sl_no')}}</th>
                                                            <th>{{$t('soil_management.characteristics_type')}}</th>
                                                            <th>{{$t('soil_management.characteristics')}}</th>
                                                            <th>{{$t('soil_management.details')}}</th>
                                                            <th>{{$t('soil_management.effect_on_plants')}}</th>
                                                            <th>{{$t('globalTrans.action')}}</th>
                                                        </tr>
                                                    </thead>
                                                    <b-tr v-for="(item, index) in mainData.details" :key="index">
                                                        <td>{{ $n(index + 1) }}</td>
                                                        <b-td>
                                                            <ValidationProvider name="Characteristics Type" vid="charac_type_id" rules="required|min_value:1">
                                                                <b-form-group
                                                                    label-cols-sm="12"
                                                                    label-for="charac_type_id"
                                                                    slot-scope="{ valid, errors }"
                                                                >
                                                                    <b-form-select
                                                                        plain
                                                                        v-model="item.char_type_id"
                                                                        :options="characteristicsTypeList"
                                                                        id="charac_type_id"
                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                        >
                                                                        <template v-slot:first>
                                                                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                                                        </template>
                                                                    </b-form-select>
                                                                    <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-td>
                                                        <b-td>
                                                            <ValidationProvider name="Characteristics" vid="charac_id" rules="required|min_value:1">
                                                                <b-form-group
                                                                    class="row"
                                                                    label-cols-sm="12"
                                                                    label-for="charac_id"
                                                                    slot-scope="{ valid, errors }"
                                                                >
                                                                    <b-form-select
                                                                    plain
                                                                    v-model="item.char_id"
                                                                    :options="characteristicsList"
                                                                    id="charac_id"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                    >
                                                                    <template v-slot:first>
                                                                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                                                    </template>
                                                                    </b-form-select>
                                                                    <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-td>
                                                        <b-td>
                                                            <ValidationProvider name="Details" vid="details_id">
                                                                <b-form-group
                                                                    class="row"
                                                                    label-cols-sm="12"
                                                                    label-for="details_id"
                                                                    slot-scope="{ valid, errors }"
                                                                >
                                                                    <b-form-input
                                                                    id="details_id"
                                                                    v-model="item.details"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                    ></b-form-input>
                                                                    <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-td>
                                                        <b-td>
                                                            <ValidationProvider name="Effect on Plant" vid="effect_id">
                                                                <b-form-group
                                                                    class="row"
                                                                    label-cols-sm="12"
                                                                    label-for="effect_id"
                                                                    slot-scope="{ valid, errors }"
                                                                >
                                                                    <b-form-input
                                                                    id="effect_id"
                                                                    v-model="item.plant_effect"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                    ></b-form-input>
                                                                    <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-td>
                                                        <b-td>
                                                            <button style="margin-top:10px" type="button" @click="remove(index)" class="btn btn- iq-bg-danger btn-sm"><i class="ri-delete-bin-line m-0"></i></button>
                                                        </b-td>
                                                    </b-tr>
                                                </table>
                                        </b-row>
                                        <br>
                                        <div class="row mt-2">
                                            <div class="col text-right">
                                                <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                                &nbsp;
                                                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-3')">{{ $t('globalTrans.cancel') }}</b-button>
                                            </div>
                                        </div>
                                    </b-form>
                                </ValidationObserver>
                                <!-- end add more  -->
                            </b-col>
                        </b-row>
                    </b-overlay>
                </template>
            </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { virtualMuseumServiceBaseUrl } from '@/config/api_config'
import { soilInfoStoreApi } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
      this.mainData.org_id = this.$store.state.dataFilters.orgId
    if (this.id) {
      const tmp = this.getSoilInfoData()
      this.mainData = tmp
      this.mainData.edit_id = this.id
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
        formLoader: false,
        saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
        mainData: {
            org_id: 0,
            crop_type_id: '',
            crop_id: '',
            details: [],
            edit_id: ''
        },
        detailData: {
          char_type_id: 0,
          char_id: 0,
          details: '',
          plant_effect: ''
        },
        cropList: [],
        charactList: []
    }
  },
  watch: {
      'mainData.crop_type_id': function (newVal) {
          this.cropList = this.getCropList(newVal)
      }
  },
  computed: {
      orgList: function () {
          const objectData = this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
          return objectData.map((obj, key) => {
              if (this.$i18n.locale === 'bn') {
                  return { value: obj.value, text: obj.text_bn }
              } else {
                  return { value: obj.value, text: obj.text_en }
              }
          })
      },
      cropTypeList: function () {
          const objectData = this.$store.state.VirtualMuseum.commonObj.cropTypeList.filter(item => item.status === 1)
          return objectData.map((obj, key) => {
              if (this.$i18n.locale === 'bn') {
                  return { value: obj.value, text: obj.text_bn }
              } else {
                  return { value: obj.value, text: obj.text_en }
              }
          })
      },
      characteristicsTypeList: function () {
          const objectData = this.$store.state.VirtualMuseum.commonObj.characteristicsTypeList.filter(item => item.status === 1)
          return objectData.map((obj, key) => {
              if (this.$i18n.locale === 'bn') {
                  return { value: obj.value, text: obj.text_bn }
              } else {
                  return { value: obj.value, text: obj.text_en }
              }
          })
      },
      characteristicsList: function () {
          const objectData = this.$store.state.VirtualMuseum.commonObj.characteristicsList.filter(item => item.status === 1)
          return objectData.map((obj, key) => {
              if (this.$i18n.locale === 'bn') {
                  return { value: obj.value, text: obj.text_bn }
              } else {
                  return { value: obj.value, text: obj.text_en }
              }
          })
      }
  },
  methods: {
    addDetailData () {
        const tmp = JSON.parse(JSON.stringify(this.detailData))
        this.mainData.details.push(tmp)
        // reset field
        this.detailData.char_type_id = 0
        this.detailData.char_id = 0
        this.detailData.details = ''
        this.detailData.plant_effect = ''
        this.$nextTick(() => {
            this.$refs.form1.reset()
        })
    },
    remove (index) {
      this.mainData.details.splice(index, 1)
    },
    getSoilInfoData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async saveData () {
        this.formLoader = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      result = await RestApi.postData(virtualMuseumServiceBaseUrl, soilInfoStoreApi, this.mainData)
      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
            this.formLoader = false
            this.$toast.success({
            title: 'Success',
            message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
            })

            this.$bvModal.hide('modal-3')
      } else {
            this.$refs.form.setErrors(result.errors)
      }
    },
    getCropList (typeId) {
        const objectData = this.$store.state.VirtualMuseum.commonObj.cropList.filter(item => item.status === 1 && item.type_id === typeId)
        return objectData.map((obj, key) => {
            if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
            } else {
                return { value: obj.value, text: obj.text_en }
            }
        })
    },
    getCharacteristicsList () {
        const charactList = this.$store.state.VirtualMuseum.commonObj.characteristicsList.filter(item => item.status === 1)
        this.charactList = charactList.filter(item => item.type_id === this.detailData.char_type_id)
        return charactList
    }
  }
}
</script>
